import React from "react";

const Footer = () => (
  <footer className="flex-shrink-0 bg-blue-100 px-2">
    <div className="max-w-screen-sm mx-auto py-4 text-blue-800 text-center">
      <span className="font-bold">© {new Date().getFullYear()}</span> built with{" "}
      <span className="pr-1" role="img" aria-label="heart">
        ❤️
      </span>{" "}
      by{" "}
      <a className="font-bold" href="https://twitter.com/fampinheiro">
        @fampinheiro
      </a>{" "}
      with{" "}
      <a className="font-bold" href="https://gatsbyjs.org">
        Gatsby
      </a>
    </div>
  </footer>
);

export default Footer;
